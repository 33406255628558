@import "index.scss";
//general
.main {
  min-height: 100vh;
}
.container {
  margin: 0 auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: $size-lg;
  color: $primary-dark;
}
//navbar
nav {
  background-color: transparent !important;
}

div[data-testid="flowbite-navbar-collapse"] ul {
  margin-left: 18px;
}
div[data-testid="flowbite-navbar-collapse"] ul li {
  width: 100%;
  margin: 0 !important;
  border-radius: 40px;
}
div[data-testid="flowbite-navbar-collapse"] ul li a {
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  color: #68788a;
  font-weight: 500;
  font-size: 14px;
  position: relative;
  width: 115px;
  height: 38.31px;
}
//footer
footer {
  background-color: $primary-dark !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  color: $primary-light !important;
}

footer li {
  margin-top: 0 !important;
  color: $primary-light !important;
  font-size: 0.9rem !important;
}

footer h2 {
  margin-bottom: 1rem !important;
  color: $primary-light !important;
  font-weight: 700 !important;
}

a[data-testid="flowbite-footer-brand"] img {
  width: 180px;
  height: auto;
}

.footer-content {
  @include font($font-header, 12px, 400, 18px, "normal");
}

.copy-right {
  @include font($font-header, 12px, 400, 18px, "normal");
}
//section
.section_div div[data-testid="flowbite-card"] {
  border: 1px solid #becad6;
  border-radius: 20px !important;
  box-shadow: none;
  min-height: 244.39px;
  height: 300px;
  &:hover {
    transition: all 0.2s;
    border-color: $theme;
    box-shadow: 0px 5px 15px rgba(190, 202, 214, 0.4);
    color: $theme !important;
  }
  &:hover svg {
    color: $theme !important;
  }
}
//options
.options_item:hover a svg {
  color: $theme !important;
}
//carousel
div[data-testid="carousel"] div:nth-child(2) {
  bottom: -2.75rem !important;
}
button[data-testid="carousel-indicator"] {
  background-color: $theme-light !important;
}
button[data-testid="carousel-indicator"].bg-white {
  background-color: $theme !important;
}
//responsive
@media only screen and (max-width: 767px) {
  div[data-testid="flowbite-navbar-collapse"] ul li a {
    width: 100%;
  }
}
