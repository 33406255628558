@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@700;800&family=Poppins:wght@100;200;300;400;500;600;700;800&family=Roboto:wght@100;300;400;500;700&display=swap");

/*------Color Pallet------*/
$theme: #007df1;
$theme-light: #eaf5ff;
$primary-light: white;
$secondary-light: #eaf5ff;
$primary-dark: #071015;
$secondary-dark: #becad6;

/*------Font------*/
$size-sm: 0.75rem;
$size-md: 0.875rem;
$size-lg: 1.2rem;

$font-header: "Poppins", "Roboto", "sans-serif", Arial, Helvetica;
$font-body: "Roboto", sans-serif, Arial, Helvetica;

/*------Mixins------*/
@mixin font($fntFam, $fntSize, $fntW, $lineH, $fontStyle) {
  font-family: $fntFam !important;
  font-size: $fntSize;
  font-weight: $fntW !important;
  line-height: $lineH;
  font-style: $fontStyle;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: $font-body, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
h1 {
  @include font($font-header, 56px, 600, 67.2px, "normal");
}
h2 {
  @include font($font-header, 48px, 600, 57.6px, "normal");
}
h3 {
  @include font($font-header, 22px, 600, 26.4px, "normal");
}

.content_1 {
  @include font($font-header, 20px, 400, 30px, "normal");
}
.content_sm1 {
  @include font($font-header, 16px, 400, 24px, "normal");
}
.content_sm3 {
  @include font($font-header, 12px, 400, 18px, "normal");
}
.content_sm4 {
  @include font($font-header, 18px, 600, 27px, "normal");
}
p {
  @include font($font-header, 20px, 400, 30px, "normal");
}
a,
h5,
h6 {
  font-family: $font-body;
}

:any-link:hover:not(#options, #navLinks, .main-button) {
  color: $theme !important;
  transition: 0.2s all;
}
:focus-visible {
  outline: none;
}

::selection {
  background: $theme;
  color: $primary-light;
}
::-moz-selection {
  background: $theme;
  color: $primary-light;
}
.main-button:is(a, button) {
  width: 133px;
  height: 43px;
  display: flex;
  justify-content: center;
  background-color: $theme;
  color: $primary-light !important;
  border: none !important;
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 16.41px;
  border-radius: 60px;
  &:is(a) {
    padding: 13.58px 14px 12.42px 12px;
  }
}
